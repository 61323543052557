<template>
  <div class="body">
    <el-table :data="tbody" class="thead-light" stripe style="width: 100%" @sort-change="sortChange"
      v-loading="loading">
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
          <template slot-scope="scope">
            <!-- 说明 -->
            <span v-if="th.prop === 'content'">举报了该会员</span>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import { MyReported } from "../../api/member/member-detail";
import Pagination from "@/base/components/Default/Pagination";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      thead: [
        { label: "ID", prop: "id", minWidth: 50 },
        { label: "会员名称", prop: "nickname", minWidth: 110 },
        { label: "会内职务", prop: "title_name", minWidth: 200 },
        { label: "说明", prop: "content", minWidth: 200 },
        {
          label: "举报时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
      ],
      filterForm: {
        id: this.data?this.$deCode(this.data).id:this.$route.params.user_id,
        page_size: 15,
      },
      tbody: [],
      pageData: {},
    };
  },
  methods: {
    // 获取被举报记录列表
    getMyReported(pageData) {
      this.loading = true;
      MyReported(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getMyReported(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getMyReported(pageData);
    },
  },
  created() {
    this.getMyReported(this.filterForm);
  },
  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);
}
</style>
